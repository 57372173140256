import { request } from "@/request/http";

export default {
  deleteNotice: (data) => {
    return request("POST", `/index.php/center/deleteNotice`, data);
  },

  saveNotice: (data) => {
    return request("GET", `/index.php/center/saveNotice`, data);
  },

  updateNewsSort: (data) => {
    return request("GET", `/index.php/center/updateNewsSort`, data);
  },

  getNoticeList: (data) => {
    return request("GET", `/index.php/center/getNoticeList`, data);
  },
}
