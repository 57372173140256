<template>
    <!-- 素材列表页 -->
    <div class="pdf">
        <!-- 搜索框 -->
        <div class="div-height div">
            <div class="left">
                <el-button
                    type="primary"
                    @click="handleOpen('is_add', '添加公告')"
                    >添加公告</el-button
                >
            </div>
        </div>
        <!-- 表格 -->
        <div class="div">
            <!-- default-expand-all 树形图打开属性 -->
            <el-table
                :data="tableData"
                style="width: 100%;margin-bottom: 20px;"
                border
            >
                <!-- 排序 -->
                <el-table-column label="排序" sortable align="center">
                    <template slot-scope="scope">
                        <span>
                            <el-input
                                v-model="scope.row.sort_no"
                                @blur="sortNoUpdate(scope.row)"
                                style="width:80px"
                            ></el-input>
                        </span>
                    </template>
                </el-table-column>
                <!-- 名称 -->
                <el-table-column prop="title" label="名称" sortable>
                </el-table-column>
                <!-- 添加时间 -->
                <el-table-column prop="addtime" label="添加时间" sortable align="center">
                </el-table-column>
                <!-- 操作 -->
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <span>
                            <el-button
                                size="mini"
                                type="primary" plain
                                @click="
                                    handleOpen(
                                        'is_delete',
                                        '删除公告',
                                        scope.$index,
                                        scope.row
                                    )
                                "
                                >删 除</el-button
                            >
                            <el-button
                                size="mini"
                                type="primary" plain
                                @click="
                                    handleOpen(
                                        'is_update',
                                        '编辑公告',
                                        scope.$index,
                                        scope.row
                                    )
                                "
                                >编 辑</el-button
                            >
                        </span>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="page-div padding-top ">
                <el-pagination
                    @current-change="handlePageChange"
                    background
                    layout="prev, pager, next"
                    :current-page.sync="page"
                    :total="dataTotal"
                    :page-size="pageSize"
                    v-if="dataTotal != 0"
                >
                </el-pagination>
            </div>
        </div>

        <el-dialog
            :title="dialogTitle"
            :visible.sync="dialogVisible"
            width="30%"
        >
            <span>
                <span
                    v-if=" handleSign != 'is_delete' "
                >
                    <el-input
                        v-model="categoryName"
                        placeholder="请输入公告名称"
                    ></el-input>
                </span>
                <span v-else class="dialog-span">您确定要删除 "{{articleTitle}}" 么？</span>
            </span>
            <span
                slot="footer"
                class="dialog-footer"
            >
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="dialogFix()">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<style lang="scss" scoped>
@import "@/assets/css/teacher/center/webNoticeList.scss";
</style>

<script>
import webNoticeList from "@/assets/js/teacher/center/webNoticeList.js";
export default {
    ...webNoticeList
};
</script>
